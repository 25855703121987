import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
          <div className="row section-head">
            <div className="ten columns">
              <p className="lead">
              Email or message me on LinkedIn to get in touch.
              </p>
            </div>
          </div>
          <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
                <h4>LinkedIn: 
                  {(' ' + resumeData.linkedinId)}<br></br>
                  Email: rio@rcostanzo.com
                </h4>
              </div>
            </aside>
          </div>
        </section>
        );
  }
}